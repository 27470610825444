import { ENDPOINTS } from '../config/api';
import { request } from '../utility/request';
import UserActions from '../action/user-actions';
import UserStore from '../store/user-store';

class AuthService {
    check() {
        if (!UserStore.isLoggedIn()) {
            return Promise.resolve(false);
        }

        return request(ENDPOINTS.CHECK_AUTH);
    }

    login() {
        let data = {};

        return request(ENDPOINTS.USER_CURRENT, data, 'GET')
            .then((response) => {
                this.getUser();
                return response;
            })
            .catch((error) => {
                throw error;
            });
    }

    getUser() {
        return request(ENDPOINTS.USER_CURRENT)
            .then((response) => {
                // TODO ENDPOINTS.USER_CURRENT returns 401 on non-auth requests
                // and results in exceptions in the JS console
                return response.json();
            })
            .then((response) => {
                let user = response.data;

                UserActions.updateUser(user);
            });
    }
}

export default new AuthService();
